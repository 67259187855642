<template>
    <v-card
        class="mb-12"
        height="500px"
    >    
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="px-4 pt-0 mt-0"
        >
            <v-container>
                <v-row>
                    <v-col :cols="12" class="pt-0 mt-0">
                        <v-radio-group v-model="item.leasedout" row>
                            <v-radio
                                label="Gepachtet"
                                :value="0"
                            ></v-radio> 
                            <v-radio
                                label="Verpachtet"
                                :value="1"
                            ></v-radio> 
                        </v-radio-group>
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col :cols="12" class="pt-0">
                    
                    <v-autocomplete
                        v-model="model"
                        :items="items"
                        :filter="customFilter"
                        :rules="[rules.required]"
                        item-text="descr"
                        item-value="id"
                        :label="lblLeaseholder"
                        placeholder="Start typing to Search"
                        prepend-icon="mdi-contacts-outline"
                        return-object
                        @change="onContactChanged"
                    ></v-autocomplete>
                
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12" lg="8" tile class="pt-0" style="min-width:200px">
                        <v-menu
                            v-model="dataTimePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template class="pr-n12" v-slot:activator="{ on, attrs }">
                                <v-text-field                                                    
                                    prepend-icon="mdi-calendar"
                                    ref="payday"
                                    v-model="item.payday"
                                    :rules="[rules.dateFormat]"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Zahltag"
                                    @change="dataTimePicker = false"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datePickerValue"
                                :first-day-of-week="0"
                                locale="de-DE"                            
                                @input="dataTimePicker = false"
                            ></v-date-picker>
                        </v-menu>                   
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12" lg="8" tile class="pt-0" style="min-width:200px">
                        <v-menu
                            v-model="dataTimePicker2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template class="pr-n12" v-slot:activator="{ on, attrs }">
                                <v-text-field                                                    
                                    prepend-icon="mdi-calendar"
                                    ref="expirationdate"
                                    v-model="item.startdate"
                                    :rules="[rules.dateFormat]"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Läuft seit"
                                    @change="dataTimePicker2 = false"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datePickerValue2"
                                :first-day-of-week="0"
                                locale="de-DE"                            
                                @input="dataTimePicker2 = false"
                            ></v-date-picker>
                        </v-menu>                   
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12" lg="8" tile class="pt-0" style="min-width:200px">
                        <v-menu
                            v-model="dataTimePicker3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template class="pr-n12" v-slot:activator="{ on, attrs }">
                                <v-text-field                                                    
                                    prepend-icon="mdi-calendar"
                                    ref="expirationdate"
                                    v-model="item.expirationdate"
                                    :rules="[rules.dateFormat]"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Läuft aus am"
                                    @change="dataTimePicker3 = false"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datePickerValue3"
                                :first-day-of-week="0"
                                locale="de-DE"                            
                                @input="dataTimePicker3 = false"
                            ></v-date-picker>
                        </v-menu>                   
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col :cols="12" class="pt-0">
                        <v-text-field
                            prepend-icon="mdi-currency-eur"
                            label="Pacht Betrag/Jahr"
                            v-model="item.amount"
                            suffix="€"
                            style="min-height: 96px">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>        
    
    </v-card>
</template>

<script>

export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    contact_id:null,
                    payday: '',
                    startdate:'',
                    expirationdate:'',
                    leasedout:0,
                    amount: '',
                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        },

        isEdit:{                
            required: true,
            default: false,
            type: Boolean
        },

        isVisible:{                
            required: true,
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            valid: false, 
            rules: {
                required: v => !!v || 'This item is required',
                dateFormat: v => /^\d{4}-\d{2}-\d{2}$|^$/.test(v)|| 'Datumsformat YYYY-mm-dd',
            }, 
            dataTimePicker: false,
            datePickerValue : '2022-01-01',

            dataTimePicker2: false,
            datePickerValue2 : '2022-01-01',

            dataTimePicker3: false,
            datePickerValue3 : '2022-01-01',

            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            search: null,            
                   
        }

    },

    computed: {

        lblLeaseholder(){

            return this.item.leasedout ? "Pächter" : "Verpächter"
        },

        currentUser() {
            return this.$store.state.auth.user;
        },


        availableContacts: function() {
            const linkedContacts = JSON.parse(JSON.stringify( this.currentUser.linkedContacts))
            
            for (const item of linkedContacts) {
                // console.debug("-----------------", item)
                item.descr = item.firstname + " " + item.lastname
                
            }

            return linkedContacts
        },
        
        items () {
            console.debug("items", this.availableContacts)
            
            return this.availableContacts
        },
    },


    methods:{
        customFilter (item, queryText) {
            console.debug("customFilter" ,item)
            const textOne = item.lastname.toLowerCase()
            const textTwo = item.firstname.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },

        onContactChanged(val){
            console.debug("onContactChanged",val)
            if(val)
                this.item.contact_id = val.id
        },

        findContact(){
            console.log('findContact', this.item)
            let res = this.items.find(item => item.id === this.item.contact_id);
            if(res)
                this.model = res
            // print
            // console.log(item.descr);

        }
 
    },

    watch: { 

        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal, this.item)
            if(this.isEdit)
                this.$refs.form.validate()
        },

        datePickerValue: function(newVal) { // watch it
            console.log('Prop changed: datePickerValue', newVal, this.item)
            this.item.payday = this.datePickerValue;
        },

        datePickerValue2: function(newVal) { // watch it
            console.log('Prop changed: datePickerValue', newVal, this.item)
            this.item.startdate = this.datePickerValue2;
        },     
        
        datePickerValue3: function(newVal) { // watch it
            console.log('Prop changed: datePickerValue', newVal, this.item)
            this.item.expirationdate = this.datePickerValue3;
        },             

        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal, this.item)
            this.state.valid = this.valid && this.item.contact_id ? true : false; 
        }, 

        isEdit: function(newVal) { // watch it
            console.log('Prop changed: isEdit', newVal, this.item)
            if(!newVal)
                this.$refs.form.reset() 
        },    
        
        isVisible: function(newVal) { // watch it
            console.log('Prop changed: isVisible', newVal, this.item)
            if(!this.isEdit)
                this.$refs.form.reset() 
        },          
    },

    mounted() {
        console.debug('mounted', this.item);
        if(this.isEdit){
            // setTimeout(() => {
                this.findContact()
                this.$refs.form.validate()
            // }, 2000);
            
        }
    }
}
</script>
