var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-12",attrs:{"height":"500px"}},[_c('v-form',{ref:"form",staticClass:"px-4 pt-0 mt-0",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":12}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.item.leasedout),callback:function ($$v) {_vm.$set(_vm.item, "leasedout", $$v)},expression:"item.leasedout"}},[_c('v-radio',{attrs:{"label":"Gepachtet","value":0}}),_c('v-radio',{attrs:{"label":"Verpachtet","value":1}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":12}},[_c('v-autocomplete',{attrs:{"items":_vm.items,"filter":_vm.customFilter,"rules":[_vm.rules.required],"item-text":"descr","item-value":"id","label":_vm.lblLeaseholder,"placeholder":"Start typing to Search","prepend-icon":"mdi-contacts-outline","return-object":""},on:{"change":_vm.onContactChanged},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",staticStyle:{"min-width":"200px"},attrs:{"md":"12","lg":"8","tile":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"payday",attrs:{"prepend-icon":"mdi-calendar","rules":[_vm.rules.dateFormat],"label":"Zahltag"},on:{"change":function($event){_vm.dataTimePicker = false}},model:{value:(_vm.item.payday),callback:function ($$v) {_vm.$set(_vm.item, "payday", $$v)},expression:"item.payday"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataTimePicker),callback:function ($$v) {_vm.dataTimePicker=$$v},expression:"dataTimePicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":0,"locale":"de-DE"},on:{"input":function($event){_vm.dataTimePicker = false}},model:{value:(_vm.datePickerValue),callback:function ($$v) {_vm.datePickerValue=$$v},expression:"datePickerValue"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",staticStyle:{"min-width":"200px"},attrs:{"md":"12","lg":"8","tile":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"expirationdate",attrs:{"prepend-icon":"mdi-calendar","rules":[_vm.rules.dateFormat],"label":"Läuft seit"},on:{"change":function($event){_vm.dataTimePicker2 = false}},model:{value:(_vm.item.startdate),callback:function ($$v) {_vm.$set(_vm.item, "startdate", $$v)},expression:"item.startdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataTimePicker2),callback:function ($$v) {_vm.dataTimePicker2=$$v},expression:"dataTimePicker2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":0,"locale":"de-DE"},on:{"input":function($event){_vm.dataTimePicker2 = false}},model:{value:(_vm.datePickerValue2),callback:function ($$v) {_vm.datePickerValue2=$$v},expression:"datePickerValue2"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",staticStyle:{"min-width":"200px"},attrs:{"md":"12","lg":"8","tile":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"expirationdate",attrs:{"prepend-icon":"mdi-calendar","rules":[_vm.rules.dateFormat],"label":"Läuft aus am"},on:{"change":function($event){_vm.dataTimePicker3 = false}},model:{value:(_vm.item.expirationdate),callback:function ($$v) {_vm.$set(_vm.item, "expirationdate", $$v)},expression:"item.expirationdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataTimePicker3),callback:function ($$v) {_vm.dataTimePicker3=$$v},expression:"dataTimePicker3"}},[_c('v-date-picker',{attrs:{"first-day-of-week":0,"locale":"de-DE"},on:{"input":function($event){_vm.dataTimePicker3 = false}},model:{value:(_vm.datePickerValue3),callback:function ($$v) {_vm.datePickerValue3=$$v},expression:"datePickerValue3"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":12}},[_c('v-text-field',{staticStyle:{"min-height":"96px"},attrs:{"prepend-icon":"mdi-currency-eur","label":"Pacht Betrag/Jahr","suffix":"€"},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }